html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Pretendard-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  word-break: keep-all !important;
}
h1 { margin-top: 0px; }
h3 { margin-top: 0px; }
a { 
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none !important;
}
a:hover { 
  color: #A1D8A0 !important;
  cursor: pointer;
  text-decoration: underline !important;
}
button {
  overflow: hidden;
  white-space: nowrap;
}
main {
  background-color: #F2F3F4 !important;
  min-height: 100vh;
  overflow-x: auto;
}
#root { overflow-x: auto; }
.hover_pointer:hover { cursor: pointer;}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.form_default > div { border-radius: 10px; }
.link_underline { text-decoration: underline !important; }
.td_onclick:hover { cursor: pointer; }
.avatar_right_name { font-weight: bold; }
.avatar_right_name:hover { cursor: pointer; text-decoration: underline; }
.select_placeholder span { color: #A4A4A4; }

.table_normal { width: 100%; }
.table_normal > table { width: 100%; table-layout: auto; border-spacing: 0; }
.table_normal th {
  border-top: 1px solid #C6E7CF;
  border-bottom: 1px solid #C6E7CF;
  background-color: #C6E7CF;
  padding: 4px 8px;
  vertical-align: middle;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table_normal td {
  border-bottom: 1px solid #C6E7CF;
  padding: 2px 8px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.table_normal th:first-child { border-left: 1px solid #C6E7CF; }
.table_normal td:first-child { border-left: 1px solid #C6E7CF; }
.table_normal th:last-child { border-right: 1px solid #C6E7CF; }
.table_normal td:last-child { border-right: 1px solid #C6E7CF; }
.table_normal > table > thead:first-child > tr:first-child > th:first-child { border-radius: 8px 0px 0px 0px; }
.table_normal > table > thead:first-child > tr:first-child > th:last-child { border-radius: 0px 8px 0px 0px; }
.table_normal > table > tbody:last-child > tr:last-child > td:first-child { border-radius: 0px 0px 0px 8px; }
.table_normal > table > tbody:last-child >tr:last-child > td:last-child { border-radius: 0px 0px 8px 0px; }


.div_data_value { border: 1px solid #C0C0C0; border-radius: 12px; padding: 16px;}
.table_container { width: 100%; overflow-x: auto; }
.data_table { width: 100%; white-space: nowrap;  text-align: left; table-layout: auto; border-spacing: 0; font-size: 14px; }
.data_table th {
  border-top: 1px solid #C6E7CF;
  border-bottom: 1px solid #C6E7CF;
  background-color: #C6E7CF;
  padding: 4px 8px;
  vertical-align: middle;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.data_table td {
  border-bottom: 1px solid #C6E7CF;
  padding: 2px 8px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.data_table th:first-child { border-left: 2px solid #C6E7CF; }
.data_table td:first-child { border-left: 2px solid #C6E7CF; }
.data_table th:last-child { border-right: 2px solid #C6E7CF; }
.data_table td:last-child { border-right: 2px solid #C6E7CF; }
.data_table tr:first-child > th:first-child { border-radius: 8px 0px 0px 0px; }
.data_table tr:first-child > th:last-child { border-radius: 0px 8px 0px 0px; }
.data_table tr:last-child > td:first-child { border-radius: 0px 0px 8px 8px; padding: 8px; }
.data_table th:hover { cursor: pointer; }
.data_table tr:hover { background-color: #F3FFF7; }
.data_table tr:last-child:hover { background-color: #FFF; }

.month_table { width: 100%; white-space: nowrap; table-layout: fixed; border-spacing: 0; font-size: 12px; }
.month_table th { text-align: center; background-color: #C6E7CF; font-weight: normal; padding: 4px 0px; }
.month_table th:first-child { color: #E80000; }
.month_table th:last-child { color: #015DE7; }
.month_table > tbody > tr > td { padding: 4px 8px 16px 8px;  }
.month_table td { vertical-align: baseline; position: relative;}
.month_table td:hover { cursor: pointer; background-color: #F3FFF7; }
.month_table td > div { min-height: 80px; }
.month_table td > .day_other { color: #C0C0C0; }
.month_table td > .day_other > p { margin: 0; }
.month_table td > .day_this { color: #252525; }
.month_table td > .day_this > p { margin: 0;}
.month_table td > .day_today { color: #FFF; }
.month_table td > .day_today > p { background-color: #A1D8A0; padding: 4px; border-radius: 50%; width: 20px; height: 20px; line-height: 100%; margin: 0; text-align: center; }
.month_table td .hidden_button { display: none; }
.month_table td:hover .hidden_button { display: grid; position: absolute; bottom: 4px; right: 4px; z-index: 999;}

.week_table { width: 100%; white-space: nowrap; table-layout: fixed; border-spacing: 0; font-size: 12px; }
.week_table th { text-align: center; background-color: #C6E7CF; font-weight: normal; padding: 4px 0px; }
.week_table th:nth-child(1) { color: #E80000; }
.week_table th:last-child { color: #015DE7; }
.week_table > tbody > tr > td { padding: 8px; }
.week_table td { vertical-align: baseline; position: relative;}
.week_table td:hover { cursor: pointer; background-color: #F3FFF7; }
.week_table td > .day_other { color: #C0C0C0; }
.week_table td > .day_other > p { margin: 0; }
.week_table td > .day_this { color: #252525; }
.week_table td > .day_this > p { margin: 0;}
.week_table td > .day_today { color: #FFF; }
.week_table td > .day_today > p { background-color: #A1D8A0; padding: 4px; border-radius: 50%; width: 20px; height: 20px; line-height: 100%; margin: 0; text-align: center; }
.week_table .today_first_td { border-top: 1px solid #C6E7CF; border-left: 1px solid #C6E7CF; border-right: 1px solid #C6E7CF; box-sizing: border-box; }
.week_table .today_middle_td { border-left: 1px solid #C6E7CF; border-right: 1px solid #C6E7CF;  box-sizing: border-box; }
.week_table .today_last_td { border-left: 1px solid #C6E7CF; border-right: 1px solid #C6E7CF; border-bottom: 1px solid #C6E7CF !important;  box-sizing: border-box; }
.week_table .other_td { border: 1px solid #FFF; }
.week_table td .hidden_button { display: none; }
.week_table td:hover .hidden_button { display: grid; position: absolute; bottom: 4px; right: 4px; z-index: 999;}

.day_table { width: 100%; white-space: nowrap; table-layout: fixed; border-spacing: 0; font-size: 12px; padding-top: 10px; }
.day_table th:first-child { width: 80px; }
.day_table th:nth-child(2) { color: #E80000; }
.day_table th:last-child { color: #015DE7; }
.day_table > tbody > tr > td { padding: 8px; }
.day_table > tbody > tr:first-child > td:nth-child(2) { border-top: 1px solid #D9D9D9;}
.day_table td { position: relative; }
.day_table td:first-child { vertical-align: top; }
.day_table td:nth-child(n+2):hover { cursor: pointer; background-color: #F3FFF7; }
.day_table td > .day_other { color: #C0C0C0; }
.day_table td > .day_other > p { margin: 0; }
.day_table td > .day_this { color: #252525; }
.day_table td > .day_this > p { margin: 0;}
.day_table td > .day_today { color: #FFF; }
.day_table td > div { min-height: 80px; }
.day_table td .hidden_button { display: none; }
.day_table td:hover .hidden_button { display: grid; position: absolute; bottom: 4px; right: 4px; z-index: 999;}

.mini_table { width: 100%; text-align: center; white-space: nowrap; table-layout: fixed; border-spacing: 0; }
.mini_table th { background-color: #C6E7CF; padding: 8px 0px; }
.mini_table tr > td:first-child { text-align: left; }
.mini_table th:first-child { border-left: 1px solid #C6E7CF; }
.mini_table td:first-child { border-left: 1px solid #C6E7CF; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.mini_table th:last-child { border-right: 1px solid #C6E7CF; }
.mini_table td:last-child { border-right: 1px solid #C6E7CF; }
.mini_table th {
  border-top: 1px solid #C6E7CF;
  border-bottom: 1px solid #C6E7CF;
}
.mini_table td {
  border-top: 1px solid #C6E7CF;
  border-bottom: 1px solid #C6E7CF;
  padding: 8px 4px;
}
.mini_table tr:first-child > th:first-child { border-radius: 8px 0px 0px 0px; }
.mini_table tr:first-child > th:last-child { border-radius: 0px 8px 0px 0px; }
.mini_table tr:last-child > td:last-child { border-radius: 0px 0px 8px 0px; }
.mini_table tr:last-child > td:first-child { border-radius: 0px 0px 0px 8px; }

.month_schedule > div { border-radius: 4px; padding: 2px; }
.month_schedule > div > div:first-child { color: #FFF; font-weight: bold; text-align: center; border-radius: 4px; overflow: hidden; white-space: nowrap; font-size: 8px;}
.month_schedule > div > div:last-child { padding-left: 4px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}

.week_schedule > div { border-radius: 4px; padding: 2px; }
.week_schedule > div > div:first-child { color: #FFF; font-weight: bold; text-align: center; border-radius: 4px; overflow: hidden; white-space: nowrap; font-size: 4px;}
.week_schedule > div > div { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.schedule_bg_modal { color:#FFF; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; border-radius: 6px; font-size: 12px; padding-top: 4px; padding-bottom: 4px;}
.schedule_border_color1 { border: 1px solid #66A7F3; border-left: solid 9px #66A7F3; }
.schedule_border_color2 { border: 1px solid #C6E7CF; border-left: solid 9px #C6E7CF; }
.schedule_border_color3 { border: 1px solid #F39966; border-left: solid 9px #F39966; }
.schedule_border_color4 { border: 1px solid #F36666; border-left: solid 9px #F36666; }
.schedule_border_color5 { border: 1px solid #EAECF0; border-left: solid 9px #EAECF0; }
.schedule_bg_color1 { background-color: #66A7F3; }
.schedule_bg_color2 { background-color: #5FD0D0; }
.schedule_bg_color3 { background-color: #8F98E9; }
.schedule_bg_color4 { background-color: #606CDF; }
.schedule_bg_color5 { background-color: #6494CC; }
.schedule_bg_color6 { background-color: #1F4A7B; }
.schedule_bg_color7 { background-color: #C0C0C0; }
.schedule_bg_color8 { background-color: #F2F3F4; color: #000 !important; }
.schedule_bg_color9 { background-color: #727272; }
.schedule_bg_color10 { background-color: #E0E5F2; color: #3355BB !important;}
.schedule_circle_color1 { color: #66A7F3; }
.schedule_circle_color2 { color: #C6E7CF; }
.schedule_circle_color3 { color: #F39966; }
.schedule_circle_color4 { color: #F36666; }
.schedule_circle_color5 { color: #EAECF0; }

.table_goal_point { width: 100%; text-align: left; border-spacing: 0; table-layout: fixed;}
.table_goal_point th { 
  border-top: 1px solid #C6E7CF;
  border-bottom: 1px solid #C6E7CF;
  background-color: #C6E7CF;
  padding: 4px 8px;
}
.table_goal_point th:first-child {
  border-right: 1px solid #FFF;
}
.table_goal_point td {
  padding: 4px 8px;
  border-bottom: 1px solid #C6E7CF;
}
.table_goal_point td:first-child {
  border-left: 1px solid #C6E7CF;
  border-right: 1px solid #C6E7CF;
}
.table_goal_point td:last-child {
  border-right: 1px solid #C6E7CF;
}
.full_width { overflow-x: visible !important; }
@media (max-width: 900px) {
  main { padding: 8px !important; }
}

.notice { padding: 20px 15px; overflow-wrap: break-word; line-height: 175%;}
.notice > h3 { text-align: center; }
.notice > div { margin-bottom: 15px; }
.notice > ul, ol > div { margin-bottom: 15px; }
.notice table.table_image { border: 1px solid #000; width: 100%; text-align: center; table-layout: fixed; margin-bottom: 15px; }
.notice table.table_image > tr:first-child td { padding-top: 16.5px; }
.notice table.table_image > tr:last-child td { padding-top: 16.5px; padding-bottom: 16.5px; }
.notice table.table_image img { width: 33%; }
.notice table.table_text { border: 1px solid #000; border-collapse: collapse; width: 100%; margin-bottom: 15px; }
.notice table.table_text > tr > td { border: 1px solid #000; border-collapse: collapse; padding: 5px; min-width: 150px; }
.notice ul.ul_dash { list-style-type: '- '; }
.notice ul.ul_none { list-style-type: none; }
.notice table.table_text_fixed { border: 1px solid #000; border-collapse: collapse; width: 100%; margin-bottom: 15px; table-layout: fixed; }
.notice table.table_text_fixed > tr > td { border: 1px solid #000; border-collapse: collapse; padding: 5px; }


.navtap { 
  padding: 0px;
  font-weight: 500; 
  font-size: 14px; 
  line-height: 20px; 
  background-color: #C6E7CF; 
  z-index: 2; 
  border-radius: 18px 18px 0px 0px; 
  /* border-image: linear-gradient(to right, red 0%, orange 100%);  */
  border: 1px solid transparent; 
  border-left: 0px; 
  border-bottom: 0px; 
  border-image-slice: 1;
  background-image: linear-gradient(#C6E7CF, #C6E7CF),  linear-gradient(90deg, #C6E7CF 0%, #A1D8A0 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: rgba(139, 139, 139, 1);

}
.navtap > div { background-color: #C6E7CF; border-radius: 18px 18px 0px 0px; }
.lasttap { border-color: #C6E7CF !important;}
.tapchecked { border-color: #C6E7CF !important; }
.tapchecked > div { background-color: #FFF !important; color: #000 !important; }
.navtap:hover { cursor: pointer;}

.gradationline { 
  border: 1px solid transparent; 
  background-image: linear-gradient(#C6E7CF, #C6E7CF), linear-gradient(0deg, #C6E7CF -194.98%, #FFFFFF -22.5%, #C6E7CF 45.97%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: 3;
}
.div_hover:hover { cursor: pointer;}